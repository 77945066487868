import {$post} from "@/api/http";
import commonModel from "@/api/common";
import tool from "@/util/tool";
import siteInfo from '@/util/global'
import moment from "moment";

class Storage{
    /**
     * 获取仓库列表信息
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getEnterPotList(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
        }
        commonModel.getDataList('ware/getDepotList',param).then(res=>cb(res))
    }

    /**
     * 添加仓库
     * @param param 添加的信息
     * @param cb
     */
    createEnterPot(param,cb){
        if( !tool.returnMessage(param.name,'请输入仓库名称')) return
        if( !tool.returnMessage(param.address,'请输入仓库地址')) return;
        if( !param.id ) delete param.id
        $post("ware/createOrSetDepot",param).then(res=>{
            tool.message("仓库添加成功")
            cb(res.data)
        })
    }

    /**
     * 改变仓库状态
     * @param scene     delete删除 disable禁用/启用
     * @param ids       仓库ids []
     * @param status    1启用 0禁用
     * @param cb
     */
    changeEnterPotStatus(scene,ids,status,cb){
        this.changeStorageInfosStatus('enterPot',scene,ids,status,res=>cb(res))
    }

    /**
     * 获取供应商信息列表
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getSupplierList(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
        }
        commonModel.getDataList('ware/getSupplierList',param).then(res=>cb(res))
    }

    /**
     * 新增、编辑供应商信息
     * @param param
     * @param cb
     */
    createSupplier(param,cb){
        if ( !tool.returnMessage(param.nickname,'请输入供应商名称')) return
        if ( !tool.returnMessage(param.contact_person,'请输入供应商联系人')) return
        if ( !tool.returnMessage(param.balance_data,'请选择余额日期')) return;
        if ( !param.id ) delete param.id
        if ( param.balance_data ){
            param.balance_data = moment(param.balance_data).format("YYYY-MM-DD")
        }
        $post('ware/createOrSetSupplier',param).then(res=>{
            tool.message("供应商创建成功")
            cb(res.data)
        })
    }

    /**
     * 改变供应商状态
     * @param scene     delete删除 disable 启用、禁用
     * @param ids       供应商id
     * @param status    1启用 0禁用
     * @param cb
     */
    changeSupplierStatus(scene,ids,status,cb){
        this.changeStorageInfosStatus('supplier',scene,ids,status,res=>cb(res))
    }

    /**
     * 导出供应商
     * @param ids
     */
    exportSupplier(ids){
        this.exportStorageInfos('supplier',ids)
    }

    /**
     * 获取客户等级
     * @param cb
     */
    getCustomerLevel(cb){
        $post("ware/getGuestLevelList",{}).then(res=>cb(res.data))
    }

    /**
     * 获取客户列表数据
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getCustomerList(page,limit,extra,cb){
        let param = { page,limit }
        if ( extra ){
            if( extra.key ) param.key = extra.key
        }
        commonModel.getDataList('ware/getGuestList',param ).then(res=>cb(res))
    }

    /**
     * 新增、编辑客户信息
     * @param param
     * @param cb
     */
    createCustomer(param,cb){
        if ( !tool.returnMessage(param.nickname,'请填写客户名称')) return
        if ( !tool.returnMessage(param.contact_person,'请填写联系人')) return
        if ( !param.id ) delete param.id
        if( param.balance_data ) {
            param.balance_data = moment(param.balance_data).format("YYYY-MM-DD")
        }
        $post('ware/createOrSetGuest',param).then(res=>{
            tool.message("客户信息保存成功~")
            cb(res.data)
        })
    }

    /**
     * 更新客户状态
     * @param scene     delete删除 disable 禁用、启用
     * @param ids       客户id
     * @param status    1启用 0禁用
     * @param cb
     */
    changeCustomerStatus(scene,ids,status,cb){
        this.changeStorageInfosStatus('customer',scene,ids,status,res=>cb(res))
    }

    /**
     * 导出客户
     * @param ids
     */
    exportCustomer(ids){
        this.exportStorageInfos('customer',ids)
    }

    /**
     * 获取产品列表数据
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getProduct(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.depot_id ) param.depot_id = extra.depot_id
        }
        commonModel.getDataList("ware/getProductList",param).then(res=>cb(res))
    }

    /**
     * 更新产品状态
     * @param scene     delete删除 disable 禁用、启用
     * @param ids       客户id
     * @param status    1启用 0禁用
     * @param cb
     */
    changeProductStatus(scene,ids,status,cb){
        this.changeStorageInfosStatus('product',scene,ids,status,res=>cb(res))
    }

    /**
     * 导出产品
     * @param ids
     */
    exportProduct(ids){
        this.exportStorageInfos('product',ids)
    }

    /**
     * 新增、编辑产品信息
     * @param param
     * @param cb
     */
    createProduct(param,cb){
        //if( !tool.returnMessage(param.name,'请输入产品名称')) return
        if( !param.id ) delete param.id
        $post("ware/createOrSetProduct",param).then(res=>{
            tool.message("产品信息保存成功")
            cb(res.data)
        })
    }

    /**
     * 获取产品计量单位
     * @param cb
     */
    getProductUnit(cb){
        $post('ware/getProductUnitList',{}).then(res=>cb(res.data))
    }

    /**
     * 创建产品计量单位
     * @param param
     * @param cb
     */
    createProductUnit(param,cb){
        if ( !tool.returnMessage(param.name,'请输入剂量单位名称')) return
        if ( !param.id ) delete param.id

        const handleUnit =data=>{
            $post('ware/createOrSetProductUnit',param).then(res=>{
                tool.message(param.status ? '产品计量单位保存成功~':'删除成功~')
                cb(res.data)
            })
        }
        console.log(param)
        if( param.status ===1){
            console.log(1111);
            handleUnit(param)
        }else{
            console.log(2222);
            tool.confirm("确认删除计量单位吗？").then(()=>{
                handleUnit(param)
            }).catch(()=>{})
        }
    }

    /**
     * 获取农资列表数据
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getFarmData(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
        }
        commonModel.getDataList('ware/getResourcesList',param).then(res=>cb(res))
    }

    /**
     * 新增、编辑农资信息
     * @param param
     * @param cb
     */
    createFarmData(param,cb){
        if( !tool.returnMessage(param.name,'请输入农资名称')) return
        if( !param.id ) delete param.id

        $post('ware/createOrSetResource',param).then(res=>{
            tool.message('农资信息保存成功~')
            cb(res.data)
        })
    }

    /**
     * 获取农资单位信息
     * @param cb
     */
    getFarmDataUnit(cb){
        $post('ware/getResourceUnitList').then(res=>cb(res.data))
    }

    /**
     * 新增、编辑、删除农资计量单位
     * @param scene add新增、编辑  delete删除
     * @param param
     * @param cb
     */
    createFarmDataUnit(scene,param,cb){
        const handleUnit = (param,msg)=>{
            $post('ware/createOrSetResourceUnit',param).then(res=>{
                tool.message(msg)
                cb(res.data)
            })
        }

        if( scene === 'add' ){
            if( !tool.returnMessage(param.name,'请输入单位名称')) return
            if( !param.id ) delete param.id
            handleUnit(param,'保存成功111~')
        }
        if( scene === 'delete' ){
            tool.confirm('确认删除计量单位信息吗?').then(()=>{
                handleUnit(param,'删除成功~')
            }).catch(()=>{})
        }
    }

    /**
     * 导出农资信息
     * @param ids
     */
    exportFarmData(ids){
        this.exportStorageInfos('farm_data',ids)
    }

    /**
     * 修改农资信息状态
     * @param scene delete删除 disable启用、禁用
     * @param ids
     * @param status    0禁用 1启用
     * @param cb
     */
    changeFarmDataStatus(scene,ids,status,cb){
       this.changeStorageInfosStatus('farm_data',scene,ids,status,res=>cb(res))
    }

    /**
     * 导出信息
     * @param scene         导出模块
     * @param ids           导出的数据id
     */
    exportStorageInfos(scene,ids){
        let api = siteInfo.siteinfo.siteroot +'/api/admin/ware/'
        let arr = {
            farm_data:'exportResource', //农资信息
            supplier:'exportSupplier',  //供应商
            customer:'exportGuest',     //客户信息
            product:'exportProduct',    //产品
        }
        api += arr[scene]+'?token='+localStorage.getItem("token")
        if( ids && ids.length > 0 ){
            api += "&ids="+JSON.stringify(ids)
        }else{
            api+="&ids=[]"
        }
        window.open(api)
    }

    /**
     * 批量修改数据禁用、启用、删除状态
     * @param at        接口类型
     * @param scene     delete 删除 disable 启用、禁用
     * @param ids
     * @param status    1启用 0禁用
     * @param cb
     */
    changeStorageInfosStatus(at,scene,ids,status,cb){
        if( !ids || ids.length === 0 ){
            tool.message("请选择要操作的信息！",'warning')
            return
        }
        let action = {
            farm_data:'ware/batchSetResource',
            product:'ware/batchSetProduct',
            customer:'ware/batchSetGuest',
            supplier:'ware/batchSetSupplier',
            enterPot:'ware/batchSetDepot'
        }
        let title = scene === 'delete' ? '确认删除信息吗？' :`确认修改状态为${status ?'启用':'禁用'}吗?`
        let param = { ids }

        if( scene === 'delete' ) param.is_delete = 1
        if( scene === 'disable') param.status = status

        tool.confirm(title).then(()=>{
            $post(action[at],param).then(res=>{
                tool.message("操作成功")
                cb(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取入库记表
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getInBoundIngo(page,limit,extra,cb){
        let param = { page,limit}
        if( extra ){
            if( extra.key ) param.key = extra.key
        }
        commonModel.getDataList("ware/getInBoundList",param).then(res=>cb(res))
    }

    /**
     * 增加入库记录
     * @param param
     * @param cb
     */
    createInBound(param,cb){
        if( !tool.returnMessage(param.class,'请选择入库方式！')) return
        if( !tool.returnMessage(param.document_date,'请选择单据日期！')) return;
        if( param.document_date ){
            param.document_date = moment(param.document_date).format("YYYY-MM-DD HH:mm:ss")
        }
        if( !param.data || param.data.length ===0 ){
            tool.message("请提交完整信息~",'warning')
            return;
        }

        $post('ware/inbound',param).then(res=>{
            tool.message('入库成功')
            cb(res.data)
        })
    }

    /**
     * 获取入库记录详情
     * @param id
     * @param cb
     */
    getBoundDetail(id,cb){
        $post("ware/getInBoundDetails",{id}).then(res=>cb(res.data))
    }

    /**
     * 获取出库记录
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getOutBound(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
        }
        commonModel.getDataList('ware/getOutBoundList',param).then(res=>cb(res))
    }

    /**
     * 出库操作
     * @param param
     * @param cb
     */
    createOutBound(param,cb){
        if( !tool.returnMessage(param.class,'请选择出库方式！')) return
        if( !tool.returnMessage(param.document_date,'请选择单据日期！')) return;
        if( param.document_date ){
            param.document_date = moment(param.document_date).format("YYYY-MM-DD HH:mm:ss")
        }
        $post('ware/outbound',param).then(res=>{
            tool.message('出库成功~')
            cb(res.data)
        })
    }

    /**
     * 获取出库记录详情
     * @param id
     * @param cb
     */
    getOutBoundDetail(id,cb){
        $post('ware/getOutBoundDetails',{id}).then(res=>cb(res.data))
    }

    /**
     * 获取入库、出库操作记录
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getBoundRecord(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.id ) param.id = extra.id
            if( extra.type ) param.type = extra.type
        }
        $post('ware/boundLogList',param).then(res=>cb(res.data))
    }

    /**
     * 获取仓储首页信息
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getWarehouse(page,limit,extra,cb){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.type ) param.type = extra.type
        }
        $post('ware/home',param).then(res=>{
            let _d = res.data
            let data = {
                list: _d.result.data,
                page:_d.result.page.currentPage,
                count:_d.result.page.totalCount,
                statics:_d.statis
            }

            cb(data)
        })
    }

    /**
     * 获取库存预警信息
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getStockWarningList(page,limit,extra,cb){
        let param = { page,limit }
        commonModel.getDataList('ware/getStockWaringList',param).then(res=>cb(res))
    }
    /**
     * 获取库存盘点信息
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getStockTalk(page,limit,extra,cb){
        let param = { page,limit }
        commonModel.getDataList('/ware/getStockInventoryList',param).then(res=>cb(res))
    }

    /**
     * 获取库存判断详情
     * @param id
     * @param cb
     */
    getStockTalkDetail(id,cb){
        $post("ware/getStockInventoryDetails",{id}).then(res=>cb(res.data))
    }

    /**
     * 盘点库存
     * @param param
     * @param cb
     */
    createStockTalk(param,cb){
        if( !tool.returnMessage(param.depot_id,'请选择仓库！')) return
        $post("ware/createOrSetStockInventory",param).then(res=>{
            tool.message("操作成功~")
            cb(res.data)
        })
    }


    /**
     * 导出盘点信息
     * @param scene all  生成盘点单据时传参  download 下载模板
     * @param od
     */
    exportStockTalk(scene , od){
        let api = siteInfo.siteinfo.siteroot +'/api/admin/ware/exportStockInventory?token='+localStorage.getItem("token")
        if( scene !== 'download'){
            if( !od || od.length ===0 ){
                tool.message("请选择要导出的信息~",'warning')
                return
            }
            api += "&od="+od
        }
        if( scene === 'all' ){
            api+='&all=true'
        }
        window.open(api)
    }

    /**
     * 导入信息
     * @param type 1. 客户 2. 供应商 3. 产品 4. 农资 5. 盘点
     * @param file
     */
    importWareInfo(type,file){
        $post('ware/import',{type,file}).then(res=>{
            tool.message('导入成功~')
        })
    }
}

const storageModel = new Storage()
export default storageModel
//427
