import {reactive, toRaw,h} from "vue";
import storageModel from "@/api/addons/storage";
import moment from "moment";
import tool from "@/util/tool";
import router from '@/router'
import siteinfo from "@/util/global";

//仓库列表操作
export function useEnterPot(){
    let epState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10,
        search:{key:''},
        ids:[],
    })
    function getEnterPot(page,limit){
        storageModel.getEnterPotList(page,limit,epState.search,res=>{
            epState.list = res.list
            epState.page = res.page
            epState.count = res.count
            epState.limit = limit
        })
    }

    const deleteEnterPot = id=>storageModel.changeEnterPotStatus('delete',id,0,()=>{
        getEnterPot(epState.page,epState.limit)
    })

    const changeEnterPot = (id,status) => storageModel.changeEnterPotStatus('disable',id,status,()=>{
        getEnterPot(epState.page,epState.limit)
    })

    return { epState,getEnterPot,deleteEnterPot,changeEnterPot }
}

//仓库新增、编辑
export function useEditEnterPot(state,fn){
    let eepState = reactive({
        show:false,
        form:null
    })

    function showEditEnterPot(data){
        eepState.form = {
            id:data ? data.id :0 ,
            name:data ? data.name :"",
            address:data ? data.address:"",
        }
        eepState.show = true
    }
    const  saveEnterPot = ()=>storageModel.createEnterPot(toRaw(eepState.form),()=> {
        fn(state.page, state.limit)
        eepState.show = false
    })

    return { eepState,saveEnterPot ,showEditEnterPot}
}

//供应商
export function useSupplier(){
    let slState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        search:{key:""},
        pks:[]
    })

    function getSupplier(page,limit){
        storageModel.getSupplierList(page,limit,slState.search,res=>{
            slState.list = res.list
            slState.page = res.page
            slState.limit = limit
            slState.count = res.count
        })
    }

    const deleteSupplier =id=>storageModel.changeSupplierStatus('delete',id,0,()=>{
        getSupplier(slState.page,slState.limit)
    })

    const changeSupplier = (id,status)=>storageModel.changeSupplierStatus('disable',id,status,()=>{
        getSupplier(slState.page,slState.limit)
    })

    const exportSupplier = ()=>storageModel.exportSupplier(slState.pks)
    const importSupplier = url=>storageModel.importWareInfo(2,url)

    return { slState ,getSupplier,deleteSupplier,changeSupplier,exportSupplier,importSupplier}
}

//供应商新增、编辑
export function useEditSupplier(state,fn){
    let esState = reactive({
        show:false,
        form:null
    })

    function showSupplier(data){
        esState.form = {
            id:data ? data.id:0,
            nickname:data?data.nickname:'',
            contact_person:data?data.contact_person:'',
            balance_data:data?moment(data.balance_data):'',
            receivables:data?data.receivables:'',
            prepaid_fee:data?data.prepaid_fee:'',
            vat:data?data.vat:'',
            tin:data?data.tin:'',
            bank:data?data.bank:'',
            bank_account:data?data.bank_account:'',
            contact_link:data?data.contact_link:'',
            mobile:data?data.mobile:'',
            remark:data?data.remark:'',
            address:data?data.address:'',
            status:data?data.status:0,
        }
        esState.show = true
    }

    const saveSupplier =()=>storageModel.createSupplier(JSON.parse(JSON.stringify(esState.form)),()=>{
        fn(state.page,state.limit)
        esState.show = false
    })

    return { esState ,showSupplier,saveSupplier }
}

//客户
export function useCustomer(){
    let cmState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10,
        search:{key:""},
        pks:[],
    })

    function getCustomer(page,limit){
        storageModel.getCustomerList(page,limit,cmState.search,res=>{
            cmState.list = res.list
            cmState.page = res.page
            cmState.count = res.count
            cmState.limit = limit
        })
    }

    const deleteCustomer =id=>storageModel.changeCustomerStatus('delete',id,0,()=>{
        getCustomer(cmState.page,cmState.limit)
    })

    const changeCustomer = (id,status)=>storageModel.changeCustomerStatus('disable',id,status,()=>{
        getCustomer(cmState.page,cmState.limit)
    })

    const exportCustomer =()=>storageModel.exportCustomer(cmState.pks)
    const importCustomer =url=>storageModel.importWareInfo(1,url)

    return { cmState,getCustomer,deleteCustomer,changeCustomer,exportCustomer,importCustomer }
}

//客户新增、编辑
export function useEditCustomer(state,fn){
    let ecState = reactive({
        show:false,
        form:null,
        level:[]
    })

    function showCustomer(data){
        ecState.form = {
            id:data ? data.id :0,
            nickname:data ? data.nickname:'',
            contact_person:data ? data.contact_person:'',
            level:data &&data.level ? data.level.id:0,
            balance_data:data ? moment(data.balance_data):'',
            receivables:data ? data.receivables:'',
            prepaid_fee:data ? data.prepaid_fee:'',
            tin:data ? data.tin:'',
            bank:data ? data.bank:'',
            bank_account:data ? data.bank_account:'',
            mobile:data ? data.mobile:'',
            contact_number:data ? data.contact_number:'',
            salesperson:data ? data.salesperson:'',
            remark:data ? data.remark:'',
            address:data ? data.address:'',
            status:data ? data.status:0,
        }
        ecState.show = true
        if( ecState.level.length === 0 ){
            storageModel.getCustomerLevel(res=>ecState.level = res)
        }
    }

    const saveCustomer= ()=>storageModel.createCustomer(JSON.parse(JSON.stringify(ecState.form)),()=>{
        fn(state.page,state.limit)
        ecState.show = false
    })

    return { ecState,saveCustomer,showCustomer }
}

//产品管理
export function useProduct(){
    let pdState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10,
        search:{key:"",depot_id:null},
        pks:[],
        enterPot:[],
    })
    storageModel.getEnterPotList(1,999,{},res=>pdState.enterPot = res.list)
    getProduct(1,pdState.limit)

    function getProduct(page,limit){
        storageModel.getProduct(page,limit,pdState.search,res=>{
            pdState.list = res.list
            pdState.page = res.page
            pdState.count = res.count
            pdState.limit = limit
        })
    }

    const deleteProduct = id=> {
        storageModel.changeProductStatus('delete', id, 0, () => {
            getProduct(pdState.page, pdState.limit)
        })
    }
    const changeProductStatus = (id,status)=> {
        storageModel.changeProductStatus('disable', id, status, () => {
            getProduct(pdState.page, pdState.limit)
        })
    }
    const exportProduct = isTemplate =>storageModel.exportProduct(pdState.pks)

    const importProduct =url=>storageModel.importWareInfo(3,url)

    return { pdState,getProduct,deleteProduct,changeProductStatus ,exportProduct,importProduct}
}

//产品新增、编辑
export function useEditProduct(state,fn){
    let epState = reactive({
        show:false,
        form:null,
        specForm:null,
        specShow:false,
        specIndex:0
    })

    function showProduct(data){
        epState.form = {
            id:data ? data.id : 0 ,
            name:data ? data.name:'',
            bar_code:data ? data.bar_code:'',
            unit_id:data ? data.unit_id:'',
            depot_id:data ? data.depot_id:'',
            early_warning:data ? data.early_warning:'',
            upper_warning_limit:data ? data.upper_warning_limit:'',
            lower_waring_limit:data ? data.lower_waring_limit:'',
            images:data ? data.images:[],
            remark:data ? data.remark:'',
            status:data ? data.status:'',
            sku:data ? data.sku:[],
        }
        epState.show = true
    }

    function saveProduct(){
        storageModel.createProduct(JSON.parse(JSON.stringify(epState.form)),()=>{
            fn(state.page,state.limit)
            epState.show = false
        })
    }

    function showProductSpec(data,index){
        epState.specForm = {
            id:data ? data.id :0 ,
            model: data ?data.model :'',
            retail_price: data ?data.retail_price :'',
            wholesale_price: data ?data.wholesale_price :'',
            vip_price: data ?data.vip_price :'',
            discount_rate1: data ?data.discount_rate1 :'',
            discount_rate2: data ?data.discount_rate2 :'',
            remark: data ?data.remark :'',
            early_warning: data ?data.early_warning :'',
            upper_warning_limit: data ?data.upper_warning_limit :'',
            lower_waring_limit: data ?data.lower_waring_limit :'',
        }
        epState.specIndex = index
        epState.specShow = true
    }

    function sureAddSpec(){
        let spec = epState.specForm , sku = epState.form.sku
        if( spec.id ){
            epState.form.sku[epState.specIndex] = spec
        }else{
            epState.form.sku.push(spec)
        }
        console.log(epState.form.sku);
        epState.specShow = false
    }

    const deleteProductSpec =index=>{
        let param = {
            id:epState.form.sku[index].product_id,
            sku:[{
                id:epState.form.sku[index].id,
                is_delete:1
            }]
        }
        storageModel.createProduct(param,()=>{
            epState.form.sku.splice(index,1)
        })
    }

    return{ epState,showProduct,saveProduct,sureAddSpec,showProductSpec ,deleteProductSpec}
}

//计量单位新增、编辑
export function useEditUnit(fn){
    let euState = reactive({
        show:false,
        form: {}
    })

    function showEditUnit(data){
        euState.form = {
            id:data ? data.id :0,
            name:data ? data.name:"",
            status:1
        }
        euState.show = true
    }
    function saveUnit(){
        storageModel.createProductUnit(JSON.parse(JSON.stringify(euState.form)),()=>{
            euState.show = false
            if( typeof fn == 'function') fn()
        })
    }

    return { euState ,showEditUnit,saveUnit }
}

//计量单位
export function useUnit(){
    let uState = reactive({
        show:false,
        list:[],
    })
    const getUnit =()=> storageModel.getProductUnit(res=>uState.list = res)

    function showUnit(){
        getUnit();
        uState.show = true
    }

    const changeUnitStatus =(data)=> {
        let param = {
            id:data.id,
            name:data.name,
            status: data.status === 1 ?0 :1
        }
        storageModel.createProductUnit(param, () => getUnit() )
    }

    return { uState ,getUnit ,showUnit,changeUnitStatus }
}

//农资管理
export function useFarmData(){
    let fdState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10,
        search:{key:''},
        pks:[]
    })
    function getFarmData(page,limit){
        storageModel.getFarmData(page,limit,fdState.search,res=>{
            fdState.list = res.list
            fdState.page = res.page
            fdState.count = res.count
            fdState.limit = limit
        })
    }

    const changeFarmData =(id,status)=>storageModel.changeFarmDataStatus('disable',id,status,()=>{
        getFarmData(fdState.page,fdState.limit)
    })
    const deleteFarmData =(id)=>storageModel.changeFarmDataStatus('delete',id,null,()=>{
        getFarmData(fdState.page,fdState.limit)
    })

    const exportFarmData=()=>storageModel.exportFarmData(fdState.pks)
    const importFarmData=url=>storageModel.importWareInfo(4,url)

    return { fdState,getFarmData,changeFarmData ,deleteFarmData,exportFarmData,importFarmData}
}

//农资新增、编辑
export function useEditFarmData(state,fn){
    let efdState = reactive({
        show:false,
        form:{},
        enterPot:[],
    })

    function showEditFarmData(data){
        efdState.form = {
            id:data ? data.id:0,
            name: data ? data.name:'',
            bar_code: data ? data.bar_code:'',
            unit_id: data ? data.unit_id:'',
            depot_id: data ? data.depot_id:'',
            images: data ? data.images:[],
            remark: data ? data.remark:'',
            early_warning: data ? data.early_warning:'',
            upper_warning_limit: data ? data.upper_warning_limit:'',
            lower_waring_limit: data ? data.lower_waring_limit:'',
            spec_model: data ? data.spec_model:'',
        }
        console.log(efdState.form);
        efdState.show = true

        if( efdState.enterPot.length === 0 ){
            storageModel.getEnterPotList(1,999,{},res=>efdState.enterPot = res.list)
        }
    }

    function saveFarmData(){
        storageModel.createFarmData(JSON.parse(JSON.stringify(efdState.form)),()=>{
            fn(state.page,state.limit)
            efdState.show = false
        })
    }

    return { efdState,showEditFarmData ,saveFarmData}
}

//农资计量单位
export function useFarmDataUnit(){
    let fduState = reactive({
        list:[],
        show:false
    })

    function getFdUnit(){
        storageModel.getFarmDataUnit(res=>fduState.list = res )
    }

    function showFdUnit(){
        getFdUnit()
        fduState.show = true
    }

    const changeFdUnitStatus =(data)=> {
        let param = {
            id:data.id,
            name:data.name,
            status: data.status === 1 ?0 :1
        }
        storageModel.createFarmDataUnit('delete',param, () => getFdUnit() )
    }

    return { fduState,getFdUnit,showFdUnit,changeFdUnitStatus }
}

//农资计量单位新增、编辑、删除
export function useEditFarmDataUnit(fn){
    let euState = reactive({
        show:false,
        form:{}
    })

    function showEditFdUnit(data){
        euState.form = {
            id:data? data.id:0,
            name:data ?data.name:"",
            status:1
        }
        euState.show = true
    }

    function saveFdUnit(){
        storageModel.createFarmDataUnit('add',euState.form,()=> {
            fn()
            euState.show = false
        })
    }

    return { euState,showEditFdUnit,saveFdUnit}
}

//入库记录
export function useInBound(){
    let ibState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        search:{key:""}
    })

    function getInBoundRecord(page,limit){
        storageModel.getInBoundIngo(page,limit,ibState.search,res=>{
            ibState.list = res.list
            ibState.page = res.page
            ibState.limit = limit
            ibState.count = res.count
        })
    }
    return { ibState,getInBoundRecord }
}

//新增入库 （第二版）
export function useAddInBound(){
    let aibState = reactive({
        entrepot:[],
        form:{
            class:1,
            document_date:'',
            data:[]
        },
        columns:[],
        temp:{
            depot_id : '', // 仓库id
            amount : '', // 数量
            unit_price : '', // 单价
            voucher : '', // 单据
            returnee : '', // 返还人
            loss : null, // 损耗程度 1. 完好无损 2. 轻微损耗 3. 中度损耗 4. 严重损耗
            desc : '', // 描述
            remark : '111', // 备注
            status : 1, // 编辑时传参 状态
            class_id : 0, // 对象id
            sku_id : 0, // 产品独传参 规格id
            productInfo:null
        }
    })
    storageModel.getEnterPotList(1,999,null,res=>aibState.entrepot = res.list)
    aibState.form.data.push(aibState.temp)
    aibState.columns = [
        {title:'操作',dataIndex:'action',slots:{ customRender:'action' } ,width:100},
        {title:'产品/规格信息',dataIndex:'product',slots: { customRender: 'product'}},
        {title:'仓库',dataIndex:'depot_id',slots: { customRender: 'depot_id'}},
        {title:'当前库存',dataIndex:'stock',slots: { customRender: 'stock'}},
        {title:'入库数量',dataIndex:'amount',slots: { customRender: 'amount'}},
        {title:'入库单价',dataIndex:'unit_price',slots: { customRender: 'unit_price'}},
        {title:'总价',dataIndex:'total',slots: { customRender: 'total'}},
        {title:'备注',dataIndex:'remark',slots: { customRender: 'remark'}},
        {title:'单据凭证',dataIndex:'voucher',slots: { customRender: 'voucher'}},
    ]
    function changeMethod(){
        if( aibState.form.class === 1 ){
            aibState.columns = [
                {title:'操作',dataIndex:'action',slots:{ customRender:'action' } ,width:100},
                {title:'产品/规格信息',dataIndex:'product',slots: { customRender: 'product'}},
                {title:'仓库',dataIndex:'depot_id',slots: { customRender: 'depot_id'}},
                {title:'当前库存',dataIndex:'stock',slots: { customRender: 'stock'}},
                {title:'入库数量',dataIndex:'amount',slots: { customRender: 'amount'}},
                {title:'入库单价',dataIndex:'unit_price',slots: { customRender: 'unit_price'}},
                {title:'总价',dataIndex:'total',slots: { customRender: 'total'}},
                {title:'备注',dataIndex:'remark',slots: { customRender: 'remark'}},
                {title:'单据凭证',dataIndex:'voucher',slots: { customRender: 'voucher'}},
            ]
        }

        if( aibState.form.class ===2 ){
            aibState.columns = [
                {title:'操作',dataIndex:'action',slots:{ customRender:'action' } ,width:100},
                {title:'产品/规格信息',dataIndex:'product',slots: { customRender: 'product'}},
                {title:'仓库',dataIndex:'depot_id',slots: { customRender: 'depot_id'}},
                {title:'当前库存',dataIndex:'stock',slots: { customRender: 'stock'}},
                {title:'入库数量',dataIndex:'amount',slots: { customRender: 'amount'}},
                {title:'返还人',dataIndex:'returnee',slots: { customRender: 'returnee'}},
                {title:'物品状态',dataIndex:'loss',slots: { customRender: 'loss'}},
                {title:'备注',dataIndex:'remark',slots: { customRender: 'remark'}},
                {title:'详细描述',dataIndex:'desc',slots: { customRender: 'desc'}},
                {title:'单据凭证',dataIndex:'voucher',slots: { customRender: 'voucher'}},
            ]
        }

        if( aibState.form.class === 3 ){
            aibState.columns = [
                {title:'操作',dataIndex:'action',slots:{ customRender:'action' } ,width:100},
                {title:'农资名称',dataIndex:'farm',slots: { customRender: 'farm'}},
                {title:'仓库',dataIndex:'depot_id',slots: { customRender: 'depot_id'}},
                {title:'当前库存',dataIndex:'stock',slots: { customRender: 'stock'}},
                {title:'入库数量',dataIndex:'amount',slots: { customRender: 'amount'}},
                {title:'入库单价',dataIndex:'unit_price',slots: { customRender: 'unit_price'}},
                {title:'总价',dataIndex:'total',slots: { customRender: 'total'}},
                {title:'备注',dataIndex:'remark',slots: { customRender: 'remark'}},
                {title:'单据凭证',dataIndex:'voucher',slots: { customRender: 'voucher'}},
            ]
        }
        aibState.form.data = [aibState.temp]
    }

    function getSelectProduct(e){
        if( !e ) return
        aibState.form.data.splice(e.index,1)
        e.data.forEach((item,index)=>{
            let temp = {
                depot_id : item.depot_id, // 仓库id
                amount : '', // 数量
                unit_price : '', // 单价
                voucher : '', // 单据
                returnee : '', // 返还人
                loss : null, // 损耗程度 1. 完好无损 2. 轻微损耗 3. 中度损耗 4. 严重损耗
                desc : '', // 描述
                remark : '', // 备注
                status : 1, // 编辑时传参 状态
                class_id : item.id, // 对象id
                sku_id : item.kd_spec ? item.kd_spec.id :'', // 产品独传参 规格id
                productInfo:item,

            }
            if( index === 0 ){
                aibState.form.data.splice(e.index,0,temp)
            }else{
                aibState.form.data.splice(e.index+1,0,temp)
            }
        })
    }

    function addOrDelProduct(scene,index){
        if( scene === 'add' ) aibState.form.data.splice(index+1,0, aibState.temp)
        if( scene === 'del' ) aibState.form.data.splice(index,1)
    }

    function saveInBound(){
        let form = JSON.parse(JSON.stringify(aibState.form))
        let param = {
            class:form.class,
            document_date:form.document_date,
            data:[]
        }

        if( form.data.length > 0 ){
            form.data.forEach(item=>{
                if( item.class_id ) param.data.push(item)
            })
        }

        storageModel.createInBound(param,()=>{
            router.push('/adhibition/storage/inbound')
        })
    }

    return { aibState,getSelectProduct,addOrDelProduct ,saveInBound,changeMethod}
}

//选择产品信息
export function useCheckProduct(){
    let cpaState = reactive({
        list:[],
        page:1,
        search:{ key :"" },
        show:false,
        checkedData:[],
    })

    let cpState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        search:{ key :"" },
        show:false,
        pks:[]
    })

    function showProductByPage(){
        cpState.show = true
        getProductList(1,cpState.limit)
    }

    //翻页获取产品信息
    function getProductList(page,limit){
        storageModel.getProduct(page,limit,cpState.search,res=>{
            cpState.list = neatenProductSpec(res.list,false)
            cpState.page = res.page
            cpState.count = res.count
            cpState.limit = limit
        })
    }

    //懒加载获取产品信息
    function getProductListByAll(){
        cpaState.show = true
        storageModel.getProduct(cpState.page,10,cpaState.search,res=>{
            let data = neatenProductSpec(res.list,false)
            cpaState.list =cpaState.search.key ?  data : cpaState.list.concat(data)
            cpaState.page +=1
        })
    }

    //将商品信息和规格信息组合成一条数据展示
    function neatenProductSpec(data){
        if( data && data.length > 0) {
            let arr = []
            data.forEach(item=>{
                if( item.sku && item.sku.length > 0 ){
                    item.sku.forEach(val=>{
                        let temp = {
                            kd_spec:val,
                            kd_spec_id:val.id,
                            kd_checked:cpaState.checkedData.includes(val.id),
                            stock:val.stock,
                            ...item
                        }
                        arr.push(temp)
                    })
                }
            })
            console.log(cpaState.checkedData,arr);
            return arr
        }
        return []
    }

    function checkedProduct(){
        cpaState.list.forEach(item=>{
            if( cpaState.checkedData.includes(item.kd_spec_id)){
                item.kd_checked = true
            }
        })

        cpState.list.forEach(item=>{
            if( cpaState.checkedData.includes(item.kd_spec_id)){
                item.kd_checked = true
            }
        })
    }

    return { cpaState,cpState,getProductList,getProductListByAll,showProductByPage }
}

//选择农资信息
export function useCheckFarmData(){
    let cfdState = reactive({
        list:[],
        page:1,
        search:{key:""},
        show:false,
        checkedData:[],
    })
    let fdState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        pks:[]
    })

    function getFarmDataByPage(page,limit){
        storageModel.getFarmData(page,limit,null,res=>{
            fdState.list = res.list
            fdState.list.forEach(item=>{
                item.kd_checked = cfdState.checkedData.includes(item.id)
            })
            fdState.page = res.page
            fdState.count = res.count
            fdState.limit = limit
        })
    }

    function getFarmDataList(){
        storageModel.getFarmData(cfdState.page,10,cfdState.search,res=>{
            cfdState.list = cfdState.list.concat(res.list)
            cfdState.list.forEach(item=>{
                item.kd_checked = cfdState.checkedData.includes(item.id)
            })
            cfdState.page +=1
        })
    }

    return { cfdState ,getFarmDataList,fdState,getFarmDataByPage}
}


//查看凭证
export function useVoucher(){
    let vState = reactive({
        show:false,
        url:""
    })
    function showVoucher(url){
        if( url ){
            vState.show = true
            vState.url = url
            return
        }
        tool.message("未上传凭证！","warning")
    }

    return { vState,showVoucher }
}

//编辑入库记录详情
export function useUpdateBound(state,fn){
    let ubState = reactive({
        show:false,
        form: {},
        enterPot:[],
        info:null
    })

    function showUpdateBound(id){
        storageModel.getBoundDetail(id,result=>{
            let res = result[0]
            ubState.info = res
            ubState.form = {
                id:res.id,
                name:res.classInfo.name,
                class:res.class,
                depot_id : res.depot_id, // 仓库id
                amount :  res.amount, // 数量
                unit_price :  res.unit_price, // 单价
                voucher :  res.voucher, // 单据
                loss : res.loss, // 损耗程度 \r\n1. 完好无耗\r\n2. 轻微损耗\r\n3. 中度损耗\r\n4. 严重损耗
                remark : res.remark, // 备注
                status :res.status, // 状态, 编辑时传
                class_id : res.class_id, // 对象id
                sku_id : res.sku_id, // 产品规格id
                guest_id : res.guest_id, // 客户id
                seller :res.seller, // 销售员
                caller : res.caller, // 调用人
                call_type : res.call_type, // 调用类型 1. 无需归还 2. 需归还 3. 已归还
                receive_depot_id : res.receive_depot_id, // 收货仓库
                productInfo:res.classInfo,
            }
            ubState.show = true
        })

        storageModel.getEnterPotList(1,999,null,res=>ubState.enterPot = res.list)
    }

    function sureSelectProduct(e){
        ubState.form.name = e[0].name
        ubState.form.class_id = e[0].id
        ubState.form.productInfo.sku = e[0].sku
        ubState.form.sku_id = e[0].select_sku_id
        if( !e[0].sku || e[0].sku.length === 0 ){
            ubState.form.productInfo.model = ""
        }
    }

    function saveUpdateBound(){
        let param = {
            class:ubState.form.class,
            document_date:ubState.info.document_date,
            data:[
                ubState.form
            ]
        }

        storageModel.createInBound(param,()=>{
            ubState.show = false
            fn(state.page,state.limit)
        })
    }

    return{ ubState ,showUpdateBound,sureSelectProduct,saveUpdateBound}
}

//出库记录
export function useOutBound(){
    let obState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        search:{key:""}
    })

    function getOutBoundRecord(page,limit){
        storageModel.getOutBound(page,limit,obState.search,res=>{
            obState.list = res.list
            obState.page = res.page
            obState.count = res.count
            obState.limit = limit
        })
    }

    return { obState,getOutBoundRecord }
}

//增加出库记录
export function useEditOutBound(){
    let eobState = reactive({
        form:{
            class:1,
            document_date:"",
            data:[
                {
                    depot_id: "",    // 仓库id
                    amount: "",                 // 数量
                    unit_price: "",             // 单价
                    voucher: "",                // 单据
                    loss: null,                 // 1. 完好无耗 2. 轻微损耗 3. 中度损耗 4. 严重损耗
                    remark: "",                 // 备注
                    class_id: 0,                // 对象id
                    sku_id: 0,                  // 产品规格id
                    guest_id: "",               // 客户id
                    seller: "",                 // 销售员
                    caller: "",                 // 调用人
                    call_type: null,              // 调用类型 1. 无需归还 2. 需归还 3. 已归还
                    receive_depot_id: null,       // 收货仓库
                    productInfo:null
                }
            ]
        },
        columns:[ ],
        temp:{
            depot_id: "",    // 仓库id
            amount: "",                 // 数量
            unit_price: "",             // 单价
            voucher: "",                // 单据
            loss: null,                 // 1. 完好无耗 2. 轻微损耗 3. 中度损耗 4. 严重损耗
            remark: "",                 // 备注
            class_id: 0,                // 对象id
            sku_id: 0,                  // 产品规格id
            guest_id: "",               // 客户id
            seller: "",                 // 销售员
            caller: "",                 // 调用人
            call_type: null,              // 调用类型 1. 无需归还 2. 需归还 3. 已归还
            receive_depot_id: null,       // 收货仓库
            productInfo:null
        },
        entrepot:[],
    })
    storageModel.getEnterPotList(1,999,null,res=>eobState.entrepot = res.list)
    eobState.columns = [
        {title:'操作',dataIndex:'action',slots: {customRender: 'action'},width: 70,fixed: 'left'},
        {title:'产品名称',dataIndex:'name',slots: {customRender: 'name'},width:300},
        {title:'出库数量',dataIndex:'amount',slots: {customRender: 'amount'},width: 150},
        {title:'销售单价',dataIndex:'unit_price',slots: {customRender: 'unit_price'},width: 150},
        {title:'总金额',dataIndex:'total',slots: {customRender: 'total'},width: 100},
        {title:'客户',dataIndex:'guest',slots: {customRender: 'guest'},width: 120},
        {title:'销售员',dataIndex:'seller',slots: {customRender: 'seller'},width: 150},
        {title:'备注',dataIndex:'remark',slots: {customRender: 'remark'},width: 200},
        {title:'单据凭证',dataIndex:'voucher',slots: {customRender: 'voucher'},width: 150},
    ]

    function changeMethod(){
        if( eobState.form.class === 1 ){
            eobState.columns = [
                {title:'操作',dataIndex:'action',slots: {customRender: 'action'},width: 70,fixed: 'left'},
                {title:'产品名称',dataIndex:'name',slots: {customRender: 'name'},width:300},
                {title:'出库数量',dataIndex:'amount',slots: {customRender: 'amount'},width: 150},
                {title:'销售单价',dataIndex:'unit_price',slots: {customRender: 'unit_price'},width: 150},
                {title:'总金额',dataIndex:'total',slots: {customRender: 'total'},width: 100},
                {title:'客户',dataIndex:'guest',slots: {customRender: 'guest'},width: 120},
                {title:'销售员',dataIndex:'seller',slots: {customRender: 'seller'},width: 150},
                {title:'备注',dataIndex:'remark',slots: {customRender: 'remark'},width: 200},
                {title:'单据凭证',dataIndex:'voucher',slots: {customRender: 'voucher'},width: 150},
            ]
        }
        if( eobState.form.class === 2 ){
            eobState.columns = [
                {title:'操作',dataIndex:'action',slots: {customRender: 'action'},width: 70,fixed: 'left'},
                {title:'产品名称',dataIndex:'farm',slots: {customRender: 'farm'},width:300},
                {title:'出库数量',dataIndex:'amount',slots: {customRender: 'amount'},width: 150},
                {title:'调用人',dataIndex:'caller',slots: {customRender: 'caller'},width: 150},
                {title:'物品状态',dataIndex:'loss',slots: {customRender: 'loss'},width: 150},
                {title:'调用类型',dataIndex:'call_type',slots: {customRender: 'call_type'},width: 150},
                {title:'备注',dataIndex:'remark',slots: {customRender: 'remark'},width: 200},
                {title:'单据凭证',dataIndex:'voucher',slots: {customRender: 'voucher'},width: 150},
            ]
        }
        if( eobState.form.class === 3 ){
            eobState.columns = [
                {title:'操作',dataIndex:'action',slots: {customRender: 'action'},width: 70,fixed: 'left'},
                {title:'农资名称',dataIndex:'name',slots: {customRender: 'name'},width:300},
                {title:'调拨数量',dataIndex:'amount',slots: {customRender: 'amount'},width: 150},
                {title:'收货仓库',dataIndex:'receive_depot_id',slots: {customRender: 'receive_depot_id'},width: 150},
                {title:'备注',dataIndex:'remark',slots: {customRender: 'remark'},width: 200},
                {title:'单据凭证',dataIndex:'voucher',slots: {customRender: 'voucher'},width: 150},
            ]
        }
    }

    function getSelectProduct(e){
        if( !e ) return
        eobState.form.data.splice(e.index,1)
        e.data.forEach((item,index)=>{
            let temp = {
                depot_id : item.depot_id, // 仓库id
                amount : '', // 数量
                unit_price : '', // 单价
                voucher : '', // 单据
                returnee : '', // 返还人
                loss : null, // 损耗程度 1. 完好无损 2. 轻微损耗 3. 中度损耗 4. 严重损耗
                desc : '', // 描述
                remark : '', // 备注
                status : 1, // 编辑时传参 状态
                class_id : item.id, // 对象id
                sku_id : item.kd_spec ? item.kd_spec.id :'', // 产品独传参 规格id
                productInfo:item,

            }
            if( index === 0 ){
                eobState.form.data.splice(e.index,0,temp)
            }else{
                eobState.form.data.splice(e.index+1,0,temp)
            }
        })
    }

    function saveOutBound(){
        let param = JSON.parse(JSON.stringify(eobState.form))
        storageModel.createOutBound(param,()=>{
            router.push("/adhibition/storage/outbound")
        })
    }

    function addOrDelProduct(scene,index){
        if( scene === 'add' ) eobState.form.data.splice(index+1,0, eobState.temp)
        if( scene === 'del' ) eobState.form.data.splice(index,1)
    }

    const deleteProduct = index=>eobState.form.data.splice(index,1)

    return { eobState,deleteProduct,saveOutBound,changeMethod,addOrDelProduct,getSelectProduct }
}

//编辑出库记录
export function useUpdateOutBound(state,fn){
    let uobState = reactive({
        show:false,
        form:{

        },
        enterPot:[],
        info:null
    })

    function getOutBoundDetail(id){
        storageModel.getOutBoundDetail(id,result=>{
            let res = result[0]
            uobState.info = res
            uobState.form = {
                id:res.id,
                class:res.class,
                depot_id: res.depot_id,             // 仓库id
                amount: res.amount,                 // 数量
                unit_price: res.unit_price,         // 单价
                voucher: res.voucher,               // 单据
                loss: res.loss,                     // 1. 完好无耗 2. 轻微损耗 3. 中度损耗 4. 严重损耗
                remark: res.remark,                 // 备注
                class_id: res.class_id,             // 对象id
                sku_id: res.sku_id,                 // 产品规格id
                guest_id: res.guest_id,             // 客户id
                seller: res.seller,                 // 销售员
                caller: res.caller,                 // 调用人
                call_type: res.call_type,           // 调用类型 1. 无需归还 2. 需归还 3. 已归还
                receive_depot_id: res.receive_depot_id,       // 收货仓库
                productInfo:res.classInfo,
            }
        })
        uobState.show = true
        storageModel.getEnterPotList(1,999,null,res=>uobState.enterPot = res.list)
    }

    function saveUpdateOutBound(){
        let param = {
            class:uobState.form.class,
            document_date:uobState.info.document_date,
            data:[
                uobState.form
            ]
        }
        storageModel.createOutBound(param,()=>{
            uobState.show = false
            fn(state.page,state.limit)
        })
    }

    return { uobState,getOutBoundDetail,saveUpdateOutBound }
}

//入库、出库操作记录
export function useBoundRecord(){
    let brState = reactive({
        list:[],
        page:1,
        limit:999,
        count:0,
        show:false,
        search:{
            id:0,
            type:1
        }
    })

    function getBoundRecordList(page,limit){
        storageModel.getBoundRecord(page,limit,brState.search,res=>brState.list = res)
    }

    return {brState ,getBoundRecordList}
}

//仓储主页
export function useWarehouse(){
    let whState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        search:{key:'',type:'all'},
        statics:null
    })

    function getWarehouseList(page,limit){
        storageModel.getWarehouse(page,limit,whState.search,res=>{
            whState.list = res.list
            whState.page = res.page
            whState.limit = limit
            whState.count = res.count
            whState.statics = res.statics
        })
    }

    return { whState,getWarehouseList }
}

//库存预警信息
export function useStockWarn(){
    let swState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10
    })
    function getStockWarn(page,limit){
        storageModel.getStockWarningList(page,limit,null,res=>{
            swState.list = res.list
            swState.page = res.page
            swState.count = res.count
            swState.limit = limit
        })
    }

    return { swState,getStockWarn }
}

//库存盘点
export function useStockTalk(){
    let stState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10,
    })

    function getStockTalkList(page,limit){
        storageModel.getStockTalk(page,limit,null,res=>{
            stState.list = res.list
            stState.page = res.page
            stState.count = res.count
            stState.limit = limit
        })
    }
    const exportStockTalk = () =>storageModel.exportStockTalk('download',null)
    const importStockTalk=url=>storageModel.importWareInfo(5,url)

    return { stState,getStockTalkList ,importStockTalk,exportStockTalk}
}

//新增库存盘点
export function useAddStockTalk(){
    let astState = reactive({
        form:{
            depot_id:null,
            remark:"",
            data:[],
            type:1,
        },
        entrepot:[],
        columns:[
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            {title:'产品信息',dataIndex:'product',slots:{customRender:'product'}},
            {title:'盘点库存',dataIndex:'inventory_stock',slots:{customRender:'inventory_stock'}},
            {title:'盘点盈亏',dataIndex:'inventory_loss',slots:{customRender:'inventory_loss'}},
            {title:'备注',dataIndex:'remark',slots:{customRender:'remark'}},
        ],
        temp:{
            class_id:"",
            sku_id:null,
            remark:"",
            type:1,
            inventory_stock:"",
            inventory_loss:"",
            productInfo:null
        }
    })

    storageModel.getEnterPotList(1,999,null,res=>astState.entrepot = res.list)
    astState.form.data.push(astState.temp)

    function changeType(){
        astState.form.data =[astState.temp]
    }

    function getSelectProduct(e){
        if( !e ) return
        astState.form.data.splice(e.index,1)
        e.data.forEach((item,index)=>{
            let temp = {
                class_id: item.id,
                sku_id:item.kd_spec ? item.kd_spec.id :'',
                remark:"",
                status : 1,
                type:astState.form.type,
                inventory_stock:"",
                inventory_loss:"",
                productInfo:item,
            }
            if( index === 0 ){
                astState.form.data.splice(e.index,0,temp)
            }else{
                astState.form.data.splice(e.index+1,0,temp)
            }
        })
    }
    const deleteTalk = index=>astState.form.data.splice(index,1)

    function saveStockTalk(){
        let param = JSON.parse(JSON.stringify(astState.form))
        if( param.data.length === 0 ){
            tool.message("请选择并填写盘点信息！","warning")
            return
        }
        let flag = false
        param.data.forEach(item=>{
            if( item.productInfo ){
                item.class_id = item.productInfo.id
            }else{
                flag = true
            }
            delete item.productInfo
        })

        if( flag ){
            tool.message("请将信息填写完成后再保存！","warning")
            return;
        }

        storageModel.createStockTalk(param,()=>{
            router.push('/adhibition/storage/stock_taking')
        })
    }

    function addOrDelProduct(scene,index){
        if( scene === 'add' ) astState.form.data.splice(index+1,0, astState.temp)
        if( scene === 'del' ) astState.form.data.splice(index,1)

    }

    return { astState ,deleteTalk,saveStockTalk,getSelectProduct ,addOrDelProduct,changeType}
}

//库存盘点详情
export function useStockTalkDetail(id){
    let stdState = reactive({
        info: {}
    })

    storageModel.getStockTalkDetail(id,res=> stdState.info = res)
    const exportStockTalk = scene =>storageModel.exportStockTalk(scene,stdState.info.od)

    return { stdState,exportStockTalk }
}

//批量导入信息
export function useImportWareInfo(scene){
    let iwiState = reactive({
        actionUrl:siteinfo.siteinfo.siteroot+'/ware/import?token='+localStorage.getItem('token')+'&type='+scene,
        load:false
    })

    function uploadChange(info){
        if (info.file.status !== 'uploading') {
            iwiState.load = true;
        }
        if (info.file.status === 'done') {
            iwiState.load = false
            if( info.file.response.data ){
                tool.message("批量上传成功~")
                return
            }
            tool.message(info.file.response.message,"warning")
        } else if (info.file.status === 'error') {
            iwiState.load = false
            tool.message('文件上传失败',"warning")
        }
    }


    return { iwiState ,uploadChange}
}











